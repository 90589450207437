import { withAuthenticator } from '../authentication';
import Head from 'next/head';
import formatDistance from 'date-fns/formatDistance';
import { Auth } from 'aws-amplify';
import { MainContent, PageWrapper, InfoBox } from '../components';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  Heading,
  Text,
  AsyncHandler,
  Input,
  Popup,
  Button,
} from '@requity-homes/component-library';
import { IntroSlides } from '../components/intro-slides/intro-slides';
import { REMINDER_STAGES } from '../const';
import { RemindClientModal } from '../components/remind-client-modal/remind-client-modal';

import {
  usePartnerReferralsQuery,
  useUpdatePartnerInfoMutation,
  usePartnerInfoQuery,
  PartnerInfoDocument,
  ApplicationStatus,
  Invitation,
  ClientApplicationInfo,
  ReminderEmailType,
} from '../graphql/generated';
import { APPLICATION_STATUSES } from '../utils/application-statuses';

export default withAuthenticator(Index);

export function Index() {
  const [user, setUser] = useState<CurrentUserInfo>();
  const [showIntroSlides, setShowIntroSlides] = useState(false);
  const [clientToRemind, setClientToRemind] = useState(null);
  const [showAd, setShowAd] = useState(false);

  const [selectedClient, setSelectedClient] = useState<
    Invitation | ClientApplicationInfo
  >(null);

  const {
    data: data,
    loading: loading,
    error: error,
  } = usePartnerReferralsQuery();

  const router = useRouter();

  const applications = data?.partnerReferrals.applications || [];
  const applicationEmails = new Set(
    applications
      .filter((appl) => appl.applicationStatus !== ApplicationStatus.Invited)
      .map((appl) => appl.email),
  );

  // Add application in invited status to the invitations array
  const invitedApplications = applications
    .filter(
      (appl) =>
        appl.applicationStatus === ApplicationStatus.Invited &&
        !applicationEmails.has(appl.email),
    )
    .map((appl) => ({
      email: appl.email,
      givenName: appl.givenName,
      familyName: appl.familyName,
      applicationStatus: ApplicationStatus.Invited,
      createdAt: appl.createdAt,
    }));

  const invitations = [
    ...(data?.partnerReferrals.invitations || []),
    ...invitedApplications,
  ];
  const reversedInvitationsArray = [...invitations].reverse();

  const declinedApplications = applications.filter(
    (appl) =>
      appl.applicationStatus === ApplicationStatus.PreApprovalDenied ||
      appl.applicationStatus === ApplicationStatus.FullApprovalDenied ||
      appl.applicationStatus === ApplicationStatus.OfferDeclined,
  );
  const activeApplications = applications.filter(
    (appl) =>
      appl.applicationStatus === ApplicationStatus.ApplicationStarted ||
      appl.applicationStatus === ApplicationStatus.PreApprovalAccepted ||
      appl.applicationStatus === ApplicationStatus.FullApprovalStarted ||
      appl.applicationStatus === ApplicationStatus.FullApprovalSubmitted ||
      appl.applicationStatus === ApplicationStatus.SentCommitmentLetter ||
      appl.applicationStatus === ApplicationStatus.FullApprovalAccepted,
  );

  const applicationsWithOffers = applications.filter(
    (appl) =>
      appl.applicationStatus === ApplicationStatus.TouringHouses ||
      appl.applicationStatus === ApplicationStatus.PreparingOffer ||
      appl.applicationStatus === ApplicationStatus.OfferAccepted ||
      appl.applicationStatus === ApplicationStatus.FulfillConditions ||
      appl.applicationStatus === ApplicationStatus.FirmAgreement ||
      appl.applicationStatus === ApplicationStatus.ClosedWon,
  );

  useEffect(() => {
    Auth.currentUserInfo().then((userInfo) => setUser(userInfo));
  }, []);

  const { data: partnerInfoData } = usePartnerInfoQuery();

  const [updatePartnerInfo, { loading: loadingPartnerUpdate }] =
    useUpdatePartnerInfoMutation({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: PartnerInfoDocument }],
    });

  useEffect(() => {
    setShowIntroSlides(
      partnerInfoData?.partnerInfo &&
        !partnerInfoData?.partnerInfo?.hasViewedOnboarding,
    );
  }, [partnerInfoData?.partnerInfo]);

  if (showIntroSlides) {
    return (
      <IntroSlides
        setShowIntroSlides={() => {
          updatePartnerInfo({
            variables: {
              partnerInfo: {
                hasViewedOnboarding: true,
              },
            },
          });
          setShowIntroSlides(false);
        }}
      />
    );
  }

  const renderDesktopRow = (user, isInvited = false) => (
    <div
      className="hidden md:flex w-4/5 bg-neutral py-2 rounded-lg justify-center items-center px-2 hover:cursor-pointer"
      onClick={() => setSelectedClient(user)}
    >
      <Text useCase="small" className="w-1/4 break-words font-bold">
        {user.givenName}
      </Text>
      <Text useCase="small" className="w-1/4 break-words font-bold">
        {user.familyName}
      </Text>
      {isInvited && (
        <Text useCase="legal" className="w-2/4 break-words">
          {`Invite sent: ${formatDistance(
            new Date(),
            new Date(user.createdAt),
          )} ago`}
        </Text>
      )}
      {!isInvited && (
        <Text useCase="legal" className="w-2/4 break-words">
          {`${APPLICATION_STATUSES[user.applicationStatus]}:
          ${
            user.updatedAt
              ? formatDistance(new Date(), new Date(user.updatedAt))
              : formatDistance(new Date(), new Date(user.createdAt))
          } ago`}
        </Text>
      )}
    </div>
  );

  const renderMobileView = (user, isInvited = false) => (
    <div
      className="md:hidden block w-4/5 bg-neutral py-2 rounded-lg px-2 hover:cursor-pointer"
      onClick={() => setSelectedClient(user)}
    >
      <div className="">
        <Text useCase="small" className="font-bold">
          {user.givenName} {user.familyName}
        </Text>
        <div>
          {isInvited && (
            <Text useCase="legal">
              {`Invite sent: ${formatDistance(
                new Date(),
                new Date(user.createdAt),
              )} ago`}
            </Text>
          )}
          {!isInvited && (
            <Text useCase="legal">
              {`${APPLICATION_STATUSES[user.applicationStatus]}:
              ${
                user.updatedAt
                  ? formatDistance(new Date(), new Date(user.updatedAt))
                  : formatDistance(new Date(), new Date(user.createdAt))
              } ago`}
            </Text>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <PageWrapper>
      <Head>
        <title>Requity Homes | Customer Portal</title>
      </Head>
      <MainContent>
        <AsyncHandler
          loading={loading || loadingPartnerUpdate}
          error={error}
          errorMessage="There was a problem fetching partner referrals. Please refresh the page or try again later."
        >
          <div className="flex flex-col gap-6 md:flex-row flex-1 md:h-5/6">
            <div className="flex flex-col order-2 md:w-4/12">
              <div className="flex flex-col gap-4 sm:gap-6 flex-1">
                <div className="flex-1">
                  <InfoBox title="How the referral partnership works with Requity Homes?">
                    <div className="flex flex-col gap-2">
                      <Text useCase="small" className="flex gap-1 mb-1">
                        <span>1:</span>
                        <span>Invite your clients through our portal</span>
                      </Text>
                      <Text useCase="small" className="flex gap-1 mb-1">
                        <span>2:</span>
                        <span>Get your clients qualified</span>
                      </Text>
                      <Text useCase="small" className="flex gap-1 mb-1">
                        <span>3:</span>
                        <span>Clients shop for homes</span>
                      </Text>
                      <Text useCase="small" className="flex gap-1 mb-1">
                        <span>4:</span>
                        <span>Clients move in & save up</span>
                      </Text>
                      <Text useCase="small" className="flex gap-1 mb-1">
                        <span>5.</span>
                        <span>Partners earn full commission</span>
                      </Text>
                    </div>
                  </InfoBox>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-6 p-0 md:p-8 pt-0 sm:gap-8 md:gap-6 order-1 md:w-8/12 md:max-h-full md:mb-8">
              <div>
                {selectedClient && (
                  <ClientInfo
                    onClose={() => setSelectedClient(null)}
                    selectedClient={selectedClient}
                  />
                )}
                {clientToRemind && (
                  <RemindClientModal
                    onClose={() => setClientToRemind(null)}
                    client={clientToRemind}
                    user={user}
                  />
                )}
                <div className="mb-6">
                  <Text className="mb-4">
                    Hello, {user?.attributes?.given_name}!
                  </Text>
                  <Heading level="h3" element="h3" fontFace="sans">
                    You have{' '}
                    {invitations?.length +
                      activeApplications?.length +
                      applicationsWithOffers?.length ===
                    1
                      ? '1 client'
                      : `${
                          invitations?.length +
                          activeApplications?.length +
                          applicationsWithOffers?.length
                        } clients`}{' '}
                    to follow up with.
                  </Heading>
                </div>
                <div className="flex h-20 overflow-hidden bg-teal-dark rounded-lg ">
                  <div className="bg-purple flex-1 flex flex-col justify-center items-center gap-2">
                    <Text className="font-bold text-white">Invited</Text>
                    <Text className="font-bold text-white">
                      {invitations?.length}
                    </Text>
                  </div>
                  <div className="bg-green flex-1 flex flex-col justify-center items-center gap-2">
                    <Text className="font-bold text-white">Applications</Text>
                    <Text className="font-bold text-white">
                      {activeApplications?.length}
                    </Text>
                  </div>
                  <div className="bg-coral-med flex-1 flex flex-col justify-center items-center gap-2">
                    <Text className="font-bold text-white">Offers</Text>
                    <Text className="font-bold text-white">
                      {applicationsWithOffers?.length}
                    </Text>
                  </div>
                  <div className="bg-grey-dark flex-1 flex flex-col justify-center items-center gap-2">
                    <Text className="font-bold text-white">Declined</Text>
                    <Text className="font-bold text-white">
                      {declinedApplications?.length}
                    </Text>
                  </div>
                </div>
              </div>
              <div className="overflow-y-auto flex flex-col gap-4">
                <div>
                  <Heading
                    level="h5"
                    element="h6"
                    fontFace="sans"
                    className="text-purple"
                  >
                    Invited
                  </Heading>
                  <Text>
                    You’ve invited these clients to Requity Homes. Reach out to
                    help them create an account and start their application.
                  </Text>
                  {reversedInvitationsArray.length === 0 && (
                    <div className="bg-neutral py-2 rounded-lg w-4/5 px-2 mt-4">
                      <Text useCase="small">
                        You have not invited any clients yet
                      </Text>
                    </div>
                  )}
                  {reversedInvitationsArray.map((client) => (
                    <div className="flex mt-4" key={client.email}>
                      {renderDesktopRow(client, true)}
                      {renderMobileView(client, true)}
                      <div className="w-1/5">
                        <Button
                          onClick={() =>
                            setClientToRemind({
                              ...client,
                              applicationStatus: ReminderEmailType.Invited,
                            })
                          }
                          hierarchy="primary"
                          className="w-full md:h-full flex items-center justify-center md:h-full bg-purple text-white border-purple sm:py-0 sm:px-0 font-thin text-xs hover:shadow-none focus:ring-0 rounded-lg"
                        >
                          <span className="hidden md:block">Remind Client</span>
                          <span className="md:hidden">Remind</span>
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <Heading
                    level="h5"
                    element="h6"
                    fontFace="sans"
                    className="text-green"
                  >
                    Applications
                  </Heading>
                  <Text>
                    Your clients have started their applications. Reach out to
                    help them gather all supporting documents to get fully
                    approved!
                  </Text>
                  {activeApplications.length === 0 && (
                    <div className="bg-neutral py-2 rounded-lg w-4/5 px-2 mt-4">
                      <Text useCase="small">No active applications</Text>
                    </div>
                  )}
                  {activeApplications.reverse().map((clientApplication) => (
                    <div className="flex mt-4" key={clientApplication.email}>
                      {renderDesktopRow(clientApplication)}
                      {renderMobileView(clientApplication)}
                      {REMINDER_STAGES.includes(
                        clientApplication.applicationStatus,
                      ) &&
                        !clientApplication.hasUnsubscribedFromEmails && (
                          <div className="w-1/5">
                            <Button
                              onClick={() =>
                                setClientToRemind(clientApplication)
                              }
                              hierarchy="primary"
                              className="w-full md:h-full flex items-center justify-center bg-green text-white border-green sm:py-0 sm:px-0 font-thin text-xs hover:shadow-none focus:ring-0 active:bg-green rounded-lg"
                            >
                              <span className="hidden md:block">
                                Remind Client
                              </span>
                              <span className="md:hidden">Remind</span>
                            </Button>
                          </div>
                        )}
                    </div>
                  ))}
                </div>
                <div>
                  <Heading
                    level="h5"
                    element="h6"
                    fontFace="sans"
                    className="text-coral-med"
                  >
                    Offers
                  </Heading>
                  <Text>
                    Your fully approved clients are one-step away from starting
                    their home ownership journey. Reach out to assist them with
                    their home shopping.
                  </Text>
                  {applicationsWithOffers.length === 0 && (
                    <div className="bg-neutral py-2 rounded-lg w-4/5 px-2 mt-4">
                      <Text useCase="small">No active applications</Text>
                    </div>
                  )}
                  {applicationsWithOffers.reverse().map((clientApplication) => (
                    <div className="flex mt-4" key={clientApplication.email}>
                      {renderDesktopRow(clientApplication)}
                      {renderMobileView(clientApplication)}
                    </div>
                  ))}
                </div>
                <div>
                  <Heading
                    level="h5"
                    element="h6"
                    fontFace="sans"
                    className="text-gray"
                  >
                    Declined
                  </Heading>
                  <Text>
                    Your clients are currently ineligible for our rent-to-own
                    program.
                  </Text>
                  {declinedApplications.length === 0 && (
                    <div className="bg-neutral py-2 rounded-lg w-4/5 px-2 mt-4">
                      <Text useCase="small">No declined clients</Text>
                    </div>
                  )}
                  {declinedApplications.map((clientApplication) => (
                    <div className="flex mt-4" key={clientApplication.email}>
                      {renderDesktopRow(clientApplication)}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </AsyncHandler>
      </MainContent>
    </PageWrapper>
  );
}

function ClientInfo({ onClose, selectedClient }) {
  return (
    <Popup>
      <Heading level="h5" element="h6" fontFace="sans" className="mb-4">
        Client Info
      </Heading>

      <div className="flex flex-col gap-3 px-4">
        <div className="flex gap-3 md:gap-0 flex-col md:flex-row justify-between">
          <Input
            className="rounded-lg !bg-white shadow-inset"
            placeholder={selectedClient.givenName}
            type="text"
            disabled
          />
          <Input
            className="rounded-lg !bg-white shadow-inset"
            placeholder={selectedClient.familyName}
            type="text"
            disabled
          />
        </div>
        <Input
          className="rounded-lg !bg-white shadow-inset"
          placeholder={selectedClient.email}
          type="text"
          disabled
        />
        {selectedClient.phoneNumber && (
          <Input
            className="rounded-lg !bg-white shadow-inset"
            placeholder={selectedClient.phoneNumber}
            type="text"
            disabled
          />
        )}
        {selectedClient.targetCity && (
          <Input
            className="rounded-lg !bg-white shadow-inset"
            placeholder={selectedClient.targetCity}
            type="text"
            disabled
          />
        )}
        {selectedClient.note && (
          <Input
            className="rounded-lg !bg-white !h-24 shadow-inset"
            placeholder={selectedClient.note}
            type="text"
            disabled
          />
        )}
        <Button
          onClick={onClose}
          hierarchy="primary"
          className="my-4 bg-coral-medium text-white py-3 border-coral-medium shadow-md-dark w-full focus:outline-none"
        >
          Ok
        </Button>
      </div>
    </Popup>
  );
}
